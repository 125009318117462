import React  from 'react';
import {Nav, Navbar} from 'react-bootstrap';
import './NavigationContainer.scss';
import {NavLink} from 'react-router-dom';

const NavigationContainer =(porps) => {

    return (
        <Navbar bg="light" expand="lg">

                <div id="j-navbar-logo"> <img src=" /img/logo.png" className="img-fluid"
                           style={{display: "inline"}}/></div>

            <Navbar.Toggle aria-controls="basic-navbar-nav"/>
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ml-auto">
                       <NavLink to="/" className="nav-link nav-link-router">HOME</NavLink>
                       <NavLink to="/aboutUs" className="nav-link nav-link-router">ABOUT US</NavLink>
                       <NavLink to="/services" className="nav-link nav-link-router">SERVICES</NavLink>
                       <NavLink to="/care" className="nav-link nav-link-router">CARE</NavLink>
                       <NavLink to="/gallery" className="nav-link nav-link-router">GALLERY</NavLink>
                       <NavLink to="/testimonials" className="nav-link nav-link-router">TESTIMONIALS</NavLink>
                       <NavLink to="/contactUs" className="nav-link nav-link-router">CONTACT US</NavLink>

                   </Nav>


                </Navbar.Collapse>

            </Navbar>
    );


};
export default NavigationContainer;
