import React from 'react';
import {Link} from 'react-router-dom';
import Fade from 'react-reveal/Fade';
import {Zoom, Slide} from 'react-reveal';
import Flip from 'react-reveal/Flip'

const URL_JASMINE_COURT = process.env.REACT_APP_URL_JASMINE_COURT;
const URL_NEWSLETTER_PDF = process.env.REACT_APP_URL_NEWSLETTER_PDF;
const URL_BROCHURE = process.env.REACT_APP_URL_BROCHURE;
const URL_CQC_ORG = process.env.REACT_APP_URL_CQC_ORG;
const URL_FOOD_RATINGS = process.env.REACT_APP_URL_FOOD_RATINGS;
const URL_CAREHOME = process.env.REACT_APP_URL_CAREHOME;
const URL_CAREHOME_REVIEWS = process.env.REACT_APP_URL_CAREHOME_REVIEWS;
const URL_FACEBOOK = process.env.REACT_APP_URL_FACEBOOK;



const LinksFooterContainer =() => {

    return(
        <div>
            <footer id="ji-container-6-footer-1" className="container">
                <div className="container-fluid">
                    <div className="margin-only">&nbsp;</div>
                    <div className="row">

                        <div className="col-xs-12 col-sm-12 col-md-4  col-lg-4">
                            <ul className="list-unstyled clear-margins">{/* widgets */}

                                <li className="widget-container widget_nav_menu">{/* widgets list */}
                                    <Zoom top>
                                    <h1 className="title-widget">Useful links</h1>

                                    <ul>
                                        <li><i className="fa fa-angle-double-right"></i> <Link to="/">Home</Link></li>

                                        <li><i className="fa fa-angle-double-right"></i><Link to="/aboutUs">ABOUT US</Link>
                                        </li>

                                        <li><i className="fa fa-angle-double-right"></i><Link to="/services">Services</Link>
                                        </li>
                                        <li><i className="fa fa-angle-double-right"></i><Link to="/care"> Care
                                            Philosophy</Link></li>
                                        <li><i className="fa fa-angle-double-right"></i> <Link to="/gallery">Gallery</Link>
                                        </li>
                                        <li><i className="fa fa-angle-double-right"></i><Link
                                            to="/testimonials">Testimonials</Link></li>

                                        <li><i className="fa fa-angle-double-right"></i><Link
                                            to="/testimonials">Feedback</Link></li>
                                        <li><i className="fa fa-angle-double-right"></i><Link to="/contactUs"> Contact
                                            us</Link></li>

                                    </ul>
                                    </Zoom>
                                </li>

                            </ul>


                        </div>
                        {/* widgets column left end */}


                        <div className="col-xs-12 col-sm-12 col-md-4  col-lg-4">

                            <Zoom top>
                            <ul className="list-unstyled clear-margins">{/* widgets */}

                                <li className="widget-container widget_nav_menu">{/* widgets list */}

                                    <h1 className="title-widget">Useful links</h1>

                                    <ul>
                                        <li><a href={URL_JASMINE_COURT} target="_blank"><i
                                            className="fa fa-angle-double-right"></i> Sister Home</a></li>


                                        <li><a href={URL_NEWSLETTER_PDF}
                                               target="_blank"><i className="fa fa-angle-double-right"></i>
                                            Newsletter</a>
                                        </li>


                                        <li><a href={URL_BROCHURE}
                                               target="_blank"><i className="fa fa-angle-double-right"></i> Digital
                                            Brochure</a>
                                        </li>

                                        <li><Link to="/contactUs"><i className="fa fa-angle-double-right"></i> Request a
                                            Brochure</Link>
                                        </li>

                                        <li><a href={URL_CQC_ORG} target="_blank"><i
                                            className="fa fa-angle-double-right"></i> Latest CQC Inspection Report</a>
                                        </li>
                                        <li><a href={URL_FOOD_RATINGS}
                                               target="_blank"><i
                                            className="fa fa-angle-double-right"></i> Food Hygine Rating</a></li>
                                        <li><a href={URL_CAREHOME}
                                               target="_blank"><i className="fa fa-angle-double-right"></i>
                                            Carehome.co.uk</a></li>


                                        <li><a
                                            href={URL_CAREHOME_REVIEWS}
                                            target="_blank"><i className="fa fa-angle-double-right"></i> Reviews</a>
                                        </li>


                                    </ul>

                                </li>

                            </ul>
                            </Zoom>


                        </div>
                        {/* widgets column left end */}


                        <div className="col-xs-12 col-sm-12 col-md-4  col-lg-4">


                            <ul className="list-unstyled clear-margins">{/* widgets */}

                                <li className="widget-container widget_recent_news">{/* widgets list */}
                                    <Zoom top>
                                    <h1 className="title-widget">Contact Detail </h1>


                                    <div className="footerp">

                                        <h2 className="title-median">GARDENIA COURT NURSING HOME</h2>
                                        <p><b>Email:</b> <a
                                            href="mailto:enquiries@gardeniacourt.co.uk">enquiries@gardeniacourt.co.uk</a>
                                        </p>

                                        <p><b>Telephone : </b>01934 632 552</p>
                                        <p><b>Fax : </b> 01934 412 711</p>

                                        <p><b>Address : </b>Gardenia Court Nursing Home,<br/>
                                            21, Uphill Road North, Weston-super-Mare,<br/>
                                            North Somerset BS23 4NG</p>

                                    </div>
                                    </Zoom>

                                    <div className="social-icons">
                                        <Fade bottom  >

                                        <ul className="nomargin">
                                            <a href={URL_FACEBOOK}
                                               target="_blank"><i className="fa fa-facebook-square fa-2x social-fb"></i></a>
                                            <a href="#"><i className="fa fa-twitter-square fa-2x social-tw"></i></a>
                                            <a href="#"><i className="fa fa-linkedin-square fa-2x social-tw"></i></a>
                                            <a href="#"><i className="fa fa-google-plus-square fa-2x social-gp"></i></a>
                                            <a href="#"><i className="fa fa-rss-square fa-2x social-em"></i></a>
                                            <a href="#"><i className="fa fa-twitter-square fa-2x social-tw"></i></a>
                                        </ul>
                                            </Fade>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>



        </div>
    );

};

export default LinksFooterContainer;