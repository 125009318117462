import React from "react";
import './HomeExternalScriptWidgets.scss';
import Flip from 'react-reveal/Flip';


const API_FOOD_RATINGS_WIDGET = process.env.REACT_APP_API_FOOD_RATINGS_WIDGET;

class HomeExternalScriptWidgets extends React.Component {
    constructor(props) {
        super(props);
        this.myRef1 = React.createRef();
    }

    componentDidMount() {
        const script1 = document.createElement("script");
        script1.src = process.env.REACT_APP_API_CQC_WIDGET;
        script1.async = true;

        this.myRef1.current.appendChild(script1);
    }

    render() {
        return (

            <div className="row">
                <Flip right>

                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 text-center responsive-gap">
                    <a href={API_FOOD_RATINGS_WIDGET} target="_blank"><img
                        className="img-fluid margin" src="/img/reviewLogo/foodAgency7.png"/></a>
                </div>
                </Flip>

                <Flip right>
               <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 text-center responsive-gap">
                    <div className='tg-review-widget-container' id='tgrw-579f54b8'></div>
                </div>
                </Flip>

                <Flip right>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 text-center responsive-gap">
                    <div ref={this.myRef1}></div>
                </div>
                </Flip>

                <Flip right>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 text-center responsive-gap">
                    <div className='tg-review-widget-container' id='tgrw-f2297353'></div>
                </div>
                </Flip>
            </div>

        );
    }
}

export default HomeExternalScriptWidgets;

