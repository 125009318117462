import React, {Suspense} from 'react';
import {BrowserRouter as Router, Route, Switch, Redirect, Link} from 'react-router-dom';
import {Spinner} from 'react-bootstrap';
import {useSelector} from "react-redux";


import './App.scss';
import "./styles/main.scss";
import "./styles/jasmine.scss";
import "./styles/navBarSection.scss";
import "./styles/mainBannerSection.scss";
import "./styles/gridLayer.scss";
import "./styles/gridLayerImages.scss";
import "./styles/topBlackLayer.scss";
import "./styles/footer.scss";
import "./styles/jasmine-override.scss";
import "./styles/formApp.scss";
import "./styles/map.scss";
import "./styles/formFeedbackApp.scss";



import NavigationContainer from "./components/navigation/NavigationContainer";
//import CareContainer from "./components/care/CareContainer";


import LinksFooterContainer from "./components/footer/LinksFooterContainer";
import DocsFooterContainer from "./components/footer/DocsFooterContainer";
import CookieConsentBanner from "./utils/CookieConsentBanner";
import StickyLinks from "./components/stickyLinks/StickyLinks";

import HomeExternalScriptWidgets from "./components/externalWidget/HomeExternalScriptWidgets";
import ReactRouterScrollToTop from "./utils/ReactRouterScrollToTop";

const LazyAbout = React.lazy(() => import('./components/aboutUs/AboutUsContainer'));
const LazyCare = React.lazy(() => import('./components/care/CareContainer'));
const LazyContactUs = React.lazy(() => import('./components/contactUs/ContactUsContainer'));
const LazyServices = React.lazy(() => import('./components/services/ServicesContainer'));
const LazyTestimonials = React.lazy(() => import('./components/testimonials/TestimonialsContainer'));
const LazyGallery = React.lazy(() => import('./components/gallery/GalleryContainer'));
const LazyHome = React.lazy(() => import('./components/home/HomeContainer'));

const LazyDocsCookiePolicy = React.lazy(() => import('./components/policy/CookiePolicy'));
const LazyDocsPrivacyPolicy = React.lazy(() => import('./components/policy/PrivacyPolicy'));
const LazyDocsSiteMap = React.lazy(() => import('./components/policy/SiteMap'));
const LazyDocsTermsOfUse = React.lazy(() => import('./components/policy/TermsOfUse'));
const LazyCustomerSurvey = React.lazy(() => import('./customerSurvey/CustomerSurvey'));
const LazyCustomerSurveyGenerate = React.lazy(() => import('./customerSurvey/CustomerSurveyGenerate'));
const LazyProfessionalSurvey = React.lazy(() => import('./customerSurvey/ProfessionalSurvey'));
const LazyProfessionalSurveyGenerate = React.lazy(() => import('./customerSurvey/ProfessionalSurveyGenerate'));



const LoadingMessage =()=> {

    return(
        <div className="container" >
            <div className="d-flex justify-content-center">
                <Spinner animation="border" variant="secondary" role="status">
                    <span className="sr-only">Please Wait...</span>
                </Spinner>
            </div>

        </div>
    );
};




function App() {
    const showExternalWidgets =useSelector(state=>state.shared.showExternalWidgets);
    const showCustomerSurvey = useSelector(state => state.shared.showCustomerSurvey);

    return (
        <Router>
            <CookieConsentBanner/>
            <ReactRouterScrollToTop/>

            {!showCustomerSurvey && (
                <>
                    <div className="container">
                        <NavigationContainer/>
                    </div>
                    <StickyLinks />
                    <div className="container">
                        <div className="container-fluid j-link-top-layer">
                            &nbsp;
                        </div>
                    </div>

                </>
            )}

            <Suspense fallback={<LoadingMessage/>}>


                <Switch>
                    <Route exact path="/">
                        <LazyHome/>
                    </Route>

                    <Route path="/aboutUs">
                        <LazyAbout/>
                    </Route>
                    <Route path="/care">
                        <LazyCare/>
                    </Route>

                    <Route exact path="/contactUs">
                        <LazyContactUs/>
                    </Route>

                    <Route path="/services">
                        <LazyServices/>
                    </Route>

                    <Route path="/testimonials">
                        <LazyTestimonials/>
                    </Route>

                    <Route path="/gallery">
                        <LazyGallery/>
                    </Route>

                    <Route path="/contactUs/:formId">
                        <LazyContactUs/>
                    </Route>

                    <Route path="/sitemap">
                        <LazyHome/>
                    </Route>

                    <Route path="/policy/terms">
                        <LazyDocsTermsOfUse/>
                    </Route>

                    <Route path="/policy/cookie">
                        <LazyDocsCookiePolicy/>
                    </Route>

                    <Route path="/policy/privacy">
                        <LazyDocsPrivacyPolicy showHeading={true}/>
                    </Route>
                    <Route path="/survey/:encryptedDate">
                        <LazyCustomerSurvey/>
                    </Route>
                    <Route exact path="/generate/lkikejeul6877jHHHlgkkjkhsf97n99KK5876hs555hrfh/:surveyDate">
                        <LazyCustomerSurveyGenerate/>
                    </Route>

                    <Route path="/p-survey/:encryptedDate">
                        <LazyProfessionalSurvey/>
                    </Route>

                    <Route exact path="/generateprofessional/khletji99JJJlhiyrwirysfkh79jsfsabbutshfddssfi3355fgsojsf/:surveyDate">
                        <LazyProfessionalSurveyGenerate/>
                    </Route>

                    <Route path='*' render={() =>
                        (
                            <Redirect to="/"/>
                        )
                    }/>


                </Switch>

                </Suspense>

            {!showCustomerSurvey && (
                <>
                <div id="ji-container-4B-review-logo" className="container j-grid-container"
                     style={{display: showExternalWidgets ? 'block' : 'none'}}>
                    <div className="container-fluid">
                        <HomeExternalScriptWidgets/>

                    </div>
                </div>

                <div id="ji-line-separator" className="container">
                    <div className="container-fluid j-line-separator">
                        &nbsp;
                    </div>

                </div>


                    <LinksFooterContainer/>
                    <DocsFooterContainer/>
                    </>
            )}
        </Router>


);
}


export default App;
