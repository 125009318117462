import React from 'react';
import {Link} from 'react-router-dom';
import Zoom from 'react-reveal/Zoom';

const DocsFooterContainer =()=>{

    return(
        <div>
            <div id="ji-container-7-footer-2" className="container footer2">
                <div className="container-fluid bg-6-footer2  margin">
                    <div className="row">
                        <Zoom>

                        <div className="col-xs-12 col-sm-12 col-md-4  col-lg-4 j-footer2">
                            <ul className="j-footer-menu-list" style={{color: "white"}}>
                                <li><Link to="/policy/terms">Terms of Use</Link></li>
                                <li><Link to="/policy/privacy">Privacy policy</Link></li>
                                <li><Link to="/policy/cookie">Cookie policy</Link></li>
                                <li>Sitemap</li>
                            </ul>
                        </div>
                        </Zoom>

                        <div className="col-xs-12 col-sm-12 col-md-4  col-lg-4 j-footer2">
                            <Zoom>
                            <div id="j-footer2-grid-2" style={{paddingLeft: '40px'}}>
                                Copyright @ 2016 Gardenia Court Nursing Home<br/>
                                All rights reserved
                            </div>
                            </Zoom>
                        </div>

                        <div className="col-xs-12 col-sm-12 col-md-4  col-lg-4 j-footer2">
                            <ul className="j-footer-menu-list" style={{color: "white"}}>
                                <Zoom >
                                <li><a href="/">Design by GavJTech</a></li>
                                </Zoom>
                            </ul>
                        </div>

                    </div>
                </div>
            </div>



        </div>
    );
};

export default DocsFooterContainer;